// import header from '@/plugins/axios/header';
import axios from 'axios'
// import translateError from '@/erros';
const header = () => {
    return {
        headers: {
            'x-access-token': localStorage.getItem('token')
        }
    }
}


export default {
    state: {
        dataMaintainers: {},
        dataInstitutions: {},
    },
    mutations: {
        setdataMaintainers(state, payload) {
            state.dataMaintainers = payload;
        },
        setdataInstitutions(state, payload) {
            state.dataInstitutions = payload;
        },
    },
    actions: {
        async getApiDataMaintainers({ commit }) {
            try {
                let chartData = {
                    labels: [],
                    datasets: []
                }
                commit('setdataMaintainers', chartData);
                let { data } = await axios.get(`/manager/dashboard/count-clients`, header());
                chartData = {
                    labels: ['Ativos', 'Inativos'],
                    datasets: [
                        {
                            backgroundColor: ['#5399ee', '#EF6C00'],
                            data: [data.data.active, data.data.inative]
                        }
                    ]
                };
                commit('setdataMaintainers', chartData);
            } catch (e) {
                console.log(e)
            }
        },
        async getApiDataInstitutions({ commit }) {
            try {
                let chartData = {
                    labels: [],
                    datasets: []
                }
                commit('setdataInstitutions', chartData);
                let { data } = await axios.get(`/manager/dashboard/count-clients/count-institutions`, header());
                chartData = {
                    labels: ['Ativos', 'Inativos'],
                    datasets: [
                        {
                            backgroundColor: ['#00e676', '#e53935'],
                            data: [data.data.active, data.data.inative]
                        }
                    ]
                };
                commit('setdataInstitutions', chartData);
            } catch (e) {
                console.log(e)
            }
        },

    },
    getters: {
        getDataMaintainers(state) {
            return state.dataMaintainers;
        },
        getDataInstitutions(state) {
            return state.dataInstitutions;
        },

    }
}