// import header from '@/plugins/axios/header';
import axios from 'axios'
// import translateError from '@/erros';
const header = () => {
    return {
        headers: {
            'x-access-token': localStorage.getItem('token')
        }
    }
}


export default {
    state: {
        dataLayouts: {},
        dataTasksAutomaticsForYear: {
            labels: [],
            datasets: []
        },
    },
    mutations: {
        setdataLayouts(state, payload) {
            state.dataLayouts = payload;
        },
        setTasksAutomaticsForYear(state, payload) {
            state.dataTasksAutomaticsForYear = payload;
        },
    },
    actions: {
        async getApiDataLayouts({ commit }, idInstitution) {
            try {
                let chartData = {
                    labels: [],
                    datasets: []
                }
                commit('setdataLayouts', chartData);
                let { data } = await axios.get(`/institutions/${idInstitution}/layouts`, header());
                // console.log(data);
                let ssr = 0;
                let csr = 0;
                let ssrAuto = 0;
                let others = 0;
                data.data.forEach(layout => {
                    if (layout.serverSide && layout.automatic) {
                        ssrAuto++;
                    } else if (layout.serverSide && !layout.automatic) {
                        ssr++;
                    } else if (!layout.serverSide && !layout.automatic) {
                        csr++;
                    } else {
                        others++;
                    }
                });
                chartData = {
                    labels: ['SSR', 'CSR', 'SSR Automatic', 'Outros'],
                    datasets: [
                        {
                            backgroundColor: ['#b685f0', '#5399ee', '#dbcd0c', '#EF6C00'],
                            data: [ssr, csr, ssrAuto, others]
                        }
                    ]
                };
                commit('setdataLayouts', chartData);
            } catch (e) {
                console.log(e)
            }
        },
        async getApiTasksAutomaticsForYear({ commit }, payload) {
            try {
                let chartData = {
                    labels: [],
                    datasets: []
                }
                commit('setTasksAutomaticsForYear', chartData)
                const { data } = await axios.get(`/dashboards/institutions/${payload.idInstitution}/tasksautomaticyear/${payload.year}`, header());
                let success = [];
                let faill = [];
                data.data.forEach(dt => {
                    chartData.labels.push(dt.month);
                    success.push(dt.success);
                    faill.push(dt.faill);
                });
                chartData.datasets.push({
                    label: 'Sucessos',
                    backgroundColor: '#66BB6A',
                    data: success
                })
                chartData.datasets.push({
                    label: 'Falhas',
                    backgroundColor: '#EF5350',
                    data: faill
                })
                commit('setTasksAutomaticsForYear', chartData)
            } catch (e) {
                console.log(e)
            }
        },
    },
    getters: {
        getDataLayouts(state) {
            return state.dataLayouts;
        },
        getTasksAutomaticsForYear(state) {
            return state.dataTasksAutomaticsForYear;
        }

    }
}