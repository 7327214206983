import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import axios from 'axios'
import header from '@/plugins/axios/header';

const baseURL = `${process.env.VUE_APP_ADDRESS_BACKEND}`;

var urlParams = window.location.pathname;
var urlLayoutBackEnd = urlParams.replace('dados-flexiveis-run','dados-flexiveis');
urlLayoutBackEnd = urlLayoutBackEnd.substring(1,urlLayoutBackEnd.length);

async function getLayout() {
    try {
        if(!urlLayoutBackEnd.includes('public-layouts')) return null;
        // Faça a chamada GET usando Axios
        const response = await axios.get(`${baseURL}${urlLayoutBackEnd}`, header);
        // Exiba a resposta na página
        return response.data.data;
    } catch (error) {
        console.error('Erro ao fazer a chamada GET:', error);
    }
}

getLayout().then((data)=>{
    if(data == null){
        Vue.use(Vuetify);
                Vue.component(
                'RenderStringTemplatePublicLayouts', {
                    name: 'RenderStringTemplatePublicLayouts',
                    render(h) {
                        const render = {
                            template: "<div>Recuso bloqueado.</div>",
                            data: () => ({
                                
                            }),
                        }
                        return h(render);
                    }
                });
    } else {
        let layout = data.layout;
        const institution = data.institution;
        layout.data = eval(`(${layout.data})`);
        layout.methods = eval(`({${layout.methods}})`);
        layout.watch = eval(`({${layout.watch}})`);
        layout.computed = eval(`({${layout.computed}})`);
        layout.flexForms = layout.flexForms ? JSON.parse(layout.flexForms) : layout.flexForms;

        const { mapActions } = require("vuex");
        const { v4: uuid,  v7: uuidv7,} = require('uuid');
        //Arredondamento de casas decimais
        const Round = (value, decimalPlaces = 2) => {
            const multiplier = Math.pow(10, decimalPlaces);
            return Math.round(value * multiplier) / multiplier;
        }
        Vue.use(Vuetify);
        Vue.component(
        'RenderStringTemplatePublicLayouts', {
            name: 'RenderStringTemplatePublicLayouts',
            props: {
                template: {
                    type: String
                },
            },
            render(h) {
                const render = {
                    template: "<div>" + layout.template + "</div>",
                    data: () => ({
                        institution: data.institution,
                        axios: require('axios'),
                        openai: require('openai'),
                        File: require('file-saver'),
                        moment: require('moment'),
                        dadosFlexiveis: data.data_flexForms,
                        ...layout.data,
                        SubTasks: async (op, content) => {
                            try {
                              let resp = await this.$http.post(`/institutions/${data.institution.idInstitution}/layoutsfull/${data.layout.idLayout}/subtasks`, {op, content}, header);
                              if(resp.headers.download === 'true'){
                                let blob = new Blob(
                                  [new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                                  resp.data],
                                  {type: `${resp.headers.typefile};charset=utf-8`}
                                  );
                                  File.saveAs(blob, `${resp.headers.namefile}`);
                                  return {}
                              } else{
                                return resp.data.data;
                              }
                            } catch (err) {
                              return err;
                            }
                        }
                    }),
                    watch:{
                        ...layout.watch
                    },
                    computed:{
                        ...layout.computed
                    },
                    async mounted(){
                        let css_lau_1722692934 = `${layout.css || ''}`;
                        // Cria um elemento <style>
                        const style_lau_1722692934 = document.createElement('style');
                        style_lau_1722692934.type = 'text/css';
                        style_lau_1722692934.textContent = css_lau_1722692934;
                        // Adiciona o <style> ao <head> do documento
                        document.head.appendChild(style_lau_1722692934);
                        
                        const exec = eval(`async () => { ` + layout.mounted + ' }');
                        await exec();
                    },
                    methods: {
                        ...mapActions(['addAlert']),
                        ...layout.methods,
                    },
                    created(){
                        const exec = eval(`async () => { ` + layout.created + ' }');
                        exec();
                    },
                }
                return h(render);
            }
        });




    }
});
