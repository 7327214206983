import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './plugins'
import filter from './filters'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VMask from 'v-mask'
import JsonCSV from 'vue-json-csv' //Lib export csv
import JsonViewer from 'vue-json-viewer'

Vue.component('csv', JsonCSV);
Vue.component('JsonViewer', JsonViewer);

Vue.use(VMask)

Vue.config.productionTip = false
Vue.filter('cnpj', filter.cnpj);
Vue.filter('cpf', filter.cpf);
Vue.filter('cep', filter.cep);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
