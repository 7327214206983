import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);
Vue.component(
    'RenderString', {
    props: {
        string: {
            require: true,
            type: String
        },
        institution: {
            require: true,
            type: Object
        },
        valueData: {
            type: Object
        },
        valueFilters: {
            type: Object
        },
        functionsFilters: {
            type: String
        },
    },
    render(h) {
        const render = {
            template: "<div>" + this.string + "</div>",
            data: () => ({
                data: this.valueData,
                filters: this.valueFilters,
                listfilters: {},
                funcFil: eval(`async (payload, filters, institution, axios, File) => { let listfilters = {}; ` + this.functionsFilters + ' }'),
                institution: this.institution
            }),
            methods: {
                async execute(value) {
                    const axios = require('axios');
                    const File = require('file-saver');
                    const moment = require('moment');
                    let process = await this.funcFil(value, this.filters, this.institution, axios, File);
                    const { listfilters } = process;
                    this.listfilters = listfilters;
                },
                async functions(value) {
                    const axios = require('axios');
                    const File = require('file-saver');
                    const exec = eval(this.data.functions); // 
                    await exec(value);
                }
            }
        }
        return h(render);
    }
}
)