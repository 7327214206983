import Vue from 'vue'
import Vuex from 'vuex'

import alerts from './modules/alerts'
import user from './modules/user'
import student from './modules/student'
import dashboard from './modules/dashboard'
import managerDashboard from './modules/managerDashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    user,
    student,
    dashboard,
    managerDashboard
  }
})
