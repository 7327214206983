import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
// import { mapActions   } from 'vuex'
import store from '../store'
// import pageRender from '../components/institution/layouts/render/functionRender'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    beforeEnter: islogged,
    component: () => import('@/layouts/login/Index.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import( /* webpackChunkName: "login" */ '@/views/login/Login.vue'),
      },
    ]
  },
  {
    path: '/admin',
    beforeEnter: auth,
    component: () => import('@/layouts/admin/Index.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/Index.vue'),
      },
      {
        path: '/manager/dashboard',
        name: 'Dashboard General',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/manager/Index.vue'),
      },
      {
        path: '/manager/clients',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/clients/Index.vue'),
        children: [
          {
            path: '',
            name: 'List Clients',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/clients/Clients.vue'),
          },
          {
            path: '/manager/clients/new',
            name: 'New Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/clients/New.vue'),
          },
          {
            path: ':idClient',
            name: 'Update Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/clients/Edit.vue'),
          },
          {
            path: ':idClient/institutions',
            name: 'Institutions for Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/institutions/Institutions.vue'),
          },
          {
            path: ':idClient/institutions/new',
            name: 'New Institution for Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/institutions/New.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution',
            name: 'Updtate Institution for Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/institutions/Edit.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/configs',
            name: 'Config Institution for Client',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/institutions/configs/Index.vue'),
          },
        ]
      },
      {
        path: '/manager/banks',
        component: () => import( /* webpackChunkName: "banks" */ '@/views/admin/clients/Index.vue'),
        children:[{
          path: '',
          name: 'Bank',
          component: () => import( /* webpackChunkName: "banks" */ '@/views/admin/bank/Bank.vue'),
        },
        {
          path: '/manager/banks/new',
          name: 'BankNew',
          component: () => import( /* webpackChunkName: "banks" */ '@/views/admin/bank/TransactionNew.vue'),
        },
        {
          path: ':idTransaction',
          name: 'Bank_Transaction',
          component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/bank/TransactionEdit.vue'),
        },
      ]
      },
      {
        path: '/manager/invoicing/clients',
        component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/clients/Index.vue'),
        children: [
          {
            path: '',
            name: 'Invoicing Institutions',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/Institutions.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution',
            name: 'Institutions Purchases',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/Purchases.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/purchase/new',
            name: 'new Purchases',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/PurchasesNew.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/purchases/:idPurchase',
            name: 'edit Purchases',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/PurchasesEdit.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/purchases/:idPurchase/installments',
            name: 'installments',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/installments/Installments.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/purchases/:idPurchase/installments/:idInstallment',
            name: 'installmentsEdit',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/installments/InstallmentEdit.vue'),
          },
          {
            path: ':idClient/institutions/:idInstitution/purchases/:idPurchase/installment/new',
            name: 'installmentsNew',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/installments/InstallmentNew.vue'),
          }
        ]
      },
      {
        path: '/manager/invoicing-tasks',
        component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/clients/Index.vue'),
        children: [
          {
            path: '',
            name: 'Invoicing Tasks',
            component: () => import( /* webpackChunkName: "invoices" */ '@/views/admin/invoices/tasks/Tasks.vue'),
          }
        ]
      },
      {
        path: '/manager/groupslayouts',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/groupLayouts/Index.vue'),
        children: [
          {
            path: '',
            name: 'List groupslayouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/groupLayouts/Groupslayouts.vue'),
          },
          {
            path: '/manager/groupslayouts/new',
            name: 'New groupslayouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/groupLayouts/New.vue'),
          },
          {
            path: ':idGroupLayout',
            name: 'Update groupslayouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/groupLayouts/Edit.vue'),
          },
        ]
      },
      {
        path: '/manager/helpers',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/Index.vue'),
        children: [
          {
            path: '',
            name: 'List Helpers',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/Helpers.vue'),
          },
          {
            path: '/manager/helpers/new',
            name: 'New Helper',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/New.vue'),
          },
          {
            path: ':idHelper',
            name: 'Update Helper',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/Edit.vue'),
          },
        ]
      },
      {
        path: '/manager/leads',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/leads/Index.vue'),
        children: [
          {
            path: '',
            name: 'List Leads',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/leads/Leads.vue'),
          },
          // {
          //   path: '/manager/helpers/new',
          //   name: 'New Helper',
          //   component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/New.vue'),
          // },
          {
            path: ':idContact',
            name: 'Update Lead',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/leads/Edit.vue'),
          },
        ]
      },
      {
        path: '/manager/notifications',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/helpers/Index.vue'),
        children: [
          {
            path: '',
            name: 'List Notifications',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/notifications/Notifications.vue'),
          },
          {
            path: '/manager/notifications/new',
            name: 'New Notification',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/notifications/New.vue'),
          },
          {
            path: ':idNotification',
            name: 'Update Notification',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/notifications/Edit.vue'),
          },
        ]
      },
      {
        path: '/manager/users',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/Index.vue'),
        children: [
          {
            path: '',
            name: 'List Users',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/Users.vue'),
          },
          {
            path: '/manager/users/new',
            name: 'New User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/New.vue'),
          },
          {
            path: '/manager/users/:idUser',
            name: 'Edit User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/Edit.vue'),
          },
          {
            path: '/manager/users/:idUser/clients',
            name: 'Clients User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/clients/Clients.vue'),
          },
          {
            path: '/manager/users/:idUser/clients/:idClient',
            name: 'Client User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/clients/institutions/Institutions.vue'),
          },
          {
            path: '/manager/users/:idUser/clients/:idClient/institutions/:idInstitution',
            name: 'Institution User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/clients/institutions/configs/Index.vue'),
          },
          {
            path: '/manager/users/:idUser/customers',
            name: 'Customers User',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/admin/users/clients/Customers.vue'),
          },
        ]
      },
      {
        path: '/institutions',
        component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/Index.vue'),
        children: [
          {
            path: ':idInstitution',
            name: 'Institution',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/Institutions.vue'),
          },
          //List Layouts BusinessPartnhers
          {
            path: '/institutions/:idInstitution/businessparthners',
            name: 'businessparthners',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/businessparthners/Index.vue'),
          },
          //List Layouts Items
          {
            path: '/institutions/:idInstitution/items',
            name: 'items',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/items/Index.vue'),
          },
          //List Layouts Discounts
          {
            path: '/institutions/:idInstitution/discounts',
            name: 'discounts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/discounts/Index.vue'),
          },
          //List Layouts Contracts
          {
            path: '/institutions/:idInstitution/contracts',
            name: 'contracts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/contracts/Index.vue'),
          },
          ///Layouts
          {
            path: '/institutions/:idInstitution/layouts',
            name: 'layouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/layouts/Index.vue'),
          },
          {
            path: '/institutions/:idInstitution/layouts/new',
            name: 'New layouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/layouts/New.vue'),
          },
          {
            path: '/institutions/:idInstitution/layoutsfull/new',
            name: 'New layoutsV2',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/layouts/New_V2.vue'),
          },
          {
            path: '/institutions/:idInstitution/layouts/:idLayout',
            name: 'Edit layouts',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/layouts/Edit.vue'),
          },
          {
            path: '/institutions/:idInstitution/layoutsfull/:idLayout',
            name: 'Edit layoutsV2',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/layouts/Edit_V2.vue'),
          },
          /// Dados Flexiveis
          {
            path: '/institutions/:idInstitution/dados-flexiveis',
            name: 'Dados Flexiveis',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/dadosFlexiveis/Index.vue'),
          },
          {
            path: '/institutions/:idInstitution/dados-flexiveis/new',
            name: 'New DadosFlexiveis',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/dadosFlexiveis/New.vue'),
          },
          {
            path: '/institutions/:idInstitution/dados-flexiveis/:idLayout',
            name: 'Edit DadosFlexiveis',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/dadosFlexiveis/Edit.vue'),
          },
          /// Organization
          {
            path: '/institutions/:idInstitution/organization',
            name: 'Organization',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/organization/Index.vue'),
          },
          /// User Groups
          {
            path: '/institutions/:idInstitution/groups',
            name: 'permissions userGroups',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/users/groups/Config.vue'),
          },
          // Tasks
          {
            path: '/institutions/:idInstitution/tasks',
            name: 'List Tasks',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/tasks/Index.vue'),
          },
          ///Pessoas Institution
          {
            path: '/institutions/:idInstitution/persons',
            name: 'Persons',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/persons/Persons.vue'),
          },
          ///Users Institution
          {
            path: '/institutions/:idInstitution/users',
            name: 'users',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/users/Users.vue'),
          },
          {
            path: '/institutions/:idInstitution/users/new',
            name: 'new users',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/users/New.vue'),
          },
          {
            path: '/institutions/:idInstitution/users/:idUser',
            name: 'edit users',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/users/Edit.vue'),
          },
          {
            path: '/institutions/:idInstitution/users/:idUser/permissions',
            name: 'permissions users',
            component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/users/permissions/Permissions.vue'),
          },
        ]
      },


      {
        path: '/logs',
        name: 'Logs',
        component: () => import( /* webpackChunkName: "logs" */ '@/views/admin/logs/Index.vue'),
      },
    ]
  },
  {
    path: '/institutions/:idInstitution/csr',
    component: () => import( /* webpackChunkName: "render" */ '@/layouts/layout/Index.vue'),
    children: [
      {
        path: '/institutions/:idInstitution/csr/layouts/:idLayout',
        name: 'csr layouts',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/layouts/Csr.vue'),
      },
      {
        path: '/institutions/:idInstitution/ssr/layouts/:idLayout',
        name: 'ssr layouts',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/layouts/Ssr.vue'),
      },
      {
        path: '/institutions/:idInstitution/layouts-run/:idLayout',
        name: 'LayoutFull',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/layouts/LayoutRun.vue'),
      },
      {
        path: '/institutions/:idInstitution/public-layouts/:idLayout',
        name: 'Public Layouts',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/layouts/LayoutRunPublic.vue'),
      },
      //Dados Flexiveis
      {
        path: '/institutions/:idInstitution/dados-flexiveis-run/:idLayout',
        name: 'DadosFlexiveis',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/dadosFlexiveis/DadosFlexiveisRun.vue'),
      },
      {
        path: '/institutions/:idInstitution/public-forms/:idLayout',
        name: 'Public Forms',
        component: () => import( /* webpackChunkName: "render" */ '@/views/institution/dadosFlexiveis/DadosFlexiveisRunPublic.vue'),
      },
      // {
      //   path: '/institutions/:idInstitution/csr/businessparthners/:idBusinessParthner/render',
      //   name: 'csr render businessparthners',
      //   component: () => import( /* webpackChunkName: "institution" */ '@/views/institution/businessparthners/csr.vue'),
      // },
    ]
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import( /* webpackChunkName: "admin" */ '@/views/Error.vue'),
  },
  // {
  //   path: '*',
  //   redirect: '/error'
  // },

]

function auth(to, from, next) {
  if (localStorage.getItem('token') != undefined) {
    validate(next, 'auth');
  } else {
    next("/");
  }
}
function islogged(to, from, next) {
  if (localStorage.getItem('token') != undefined) {
    validate(next, 'islogged');
  } else {
    next();
  }
}

async function validate(next, operation) {
  await axios.post('/validate', {}, {
    headers: {
      'x-access-token': localStorage.getItem('token')
    }
  }).then(res => {
    if (res.data.permission) {
      // console.log(res.data)
      store.dispatch('addUser', res.data);
      if (operation === "islogged") {
        if (res.data.master) {
          next('/manager/dashboard');
        } else {
          // console.log('localStorage.getItem')
          // console.log(localStorage.getItem('institution'))
          next('/admin');
        }
      } else {
        const idSearch = JSON.parse(localStorage.getItem('institution')).idInstitution;
        for (let clients of store.getters.getUser.info.permissions.clients) {
          for (let ie of clients.institutions) {
            if (ie.idInstitution === idSearch) {
              localStorage.setItem('institution', JSON.stringify(ie));
              break;
            }
          }
        }
        next();
      }
    }
  }).catch(() => {
    // console.log(err.response);
    if (operation === "auth") {
      next("/");
    }
    if (operation === "islogged") {
      localStorage.removeItem('token');
      next("/");
    }
  });
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
