export default {
    state: {
        student: {}
        
    },
    mutations:{
        setStudent(state, payload){
            state.student = payload;
        },
    },
    actions:{
        addStudent({ commit }, payload){
            commit('setStudent', payload)
        }
       
    },
    getters: {
        getStudent(state){
            return state.student
        }
    }
}
