export default {
    state: {
        user: {},
        selectedClient: {
            idClient: null,
            name: null,
            institutions: [],
        },
        selectedInstitution: {
            idInstitution: null,
            name: null,
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setMenuUser(state, payload) {
            state.user.info.permissions.menu = payload;
        },
        setClient(state, payload) {
            state.selectedClient = payload;
        },
        setInstitution(state, payload) {
            state.selectedInstitution = payload;
        },
    },
    actions: {
        addUser({ commit }, payload) {
            commit('setUser', payload)
        },
        addMenuUser({ commit }, payload) {
            commit('setMenuUser', payload)
        },
        setClient({ commit }, payload) {
            commit('setClient', payload)
        },
        setInstitution({ commit }, payload) {
            commit('setInstitution', payload)
        }
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getClient(state) {
            return state.selectedClient;
        },
        getInstitution(state) {
            return state.selectedInstitution;
        }
    }
}
